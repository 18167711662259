.CalendarDay__today {
  position: relative;
}

.CalendarDay__today:not(.CalendarDay__selected)::after {
  content: '';
  position: absolute;
  top: 23px;
  left: 23px;
  transform: translate(50%, 50%);
  -webkit-transform: translate(50%, 50%);
  -moz-transform: translate(50%, 50%);
  -ms-transform: translate(50%, 50%);
  border-color: transparent;
  border-style: solid;
  border-width: 4.8px;
  border-right-color: #F16622;
  border-bottom-color: #F16622;
}

.CalendarDay__default {
  border: 1px solid white;
  font-size: 16px;
  color: #F16622;
  position: relative;
  z-index: 1;
}

.CalendarDay__default:hover {
  border: 1px solid white;
  background: white;
  color: #F16622;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: white;
  color: white;
  position: relative;
  z-index: 1;
}

.CalendarDay__default:not(.CalendarDay__blocked_calendar)::after {
  content: "";
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 25px;
  border: 1px solid #F16622;
  position: absolute;
  top: 23px;
  left: 23px;
  z-index: -1;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.CalendarMonth_table {
  margin-top: 20px;
}

.CalendarDay__selected::after {
  background: #F16622;
}

.CalendarDay__blocked_calendar {
  background: white;
  color: #B4B4B4;
}

.CalendarDay__blocked_calendar:hover {
  background: white;
  color: #B4B4B4;
}

.DayPicker__horizontal {
  flex-shrink: 0;
}

@media only screen 
  and (max-device-width: 320px)
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .CalendarDay__default:not(.CalendarDay__blocked_calendar)::after {
      width: 30px;
      height: 30px;
      top: 18px;
      left: 18px;
    }
    .CalendarDay__today:not(.CalendarDay__selected)::after {
      top: 18px;
      left: 18px;
    }
}