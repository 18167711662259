body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: #58595b;
}

.mainContainer {
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .mainContainer {
    height: -webkit-fill-available;
  }
}

.root {
  height: 100%;
}

.paddingWrapper {
  padding: 0 24px;
  font-size: 14px;
  color: #58595b;
  line-height: 16px;
  flex-grow: 1;
}

.topMessage {
  display: block;
  padding-top: 32px;
  margin-bottom: 16px;
  line-height: 1.36em;
  font-size: 1.03em;
  color: rgba(88, 89, 91, 1);
}
