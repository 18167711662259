.section {
  border-radius: 8px;
  background-color: #fff;
  width: auto;
  height: auto;
  margin: 8px 8px;
}

.detailsSection {
  text-align: left;
  padding: 16px 16px;
}


.detailsSection > h3 {
  font-size: 18px;
  line-height: 28px; 
  color: #303030;
  font-weight: 700;
  margin: 0 0 20px 0;
  padding: 0 0;
}

.detailsSection > div {
  font-size: 14px;
  color: #737373;
  line-height: 16px;
  font-weight: 500;
  margin: 0 0 16px 0;
  padding: 0 0;
}

.section-heading {
  font-size: 16px;
  line-height: 16px;
  color: #949494;
  height: 19px;
  padding: 0 0;
  margin: 0 0 8px 0;
  font-weight: 700px;
}

.section-description {
  font-size: 16px;
  line-height: 30px;
  color: #303030; 
  padding: 0 0;
  margin: 0 0;
}

.min-height-100 {
  min-height: 100px;
}

.back-button {
  position: absolute;
  left: 0;
  cursor: pointer;
  line-height: 16px;
  margin: 0 10px;
  padding: 0 0;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 48px;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: solid thin #ccc;
}

.tabs > li {
  width: 50%;
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  color: #000000;
  border-bottom: solid 2px transparent;
  cursor: pointer;
}

.tabs > li.active {
  color: #f16622;
  border-bottom-color: #f16622;
}

.tabs > .tab-data {
  text-align: left;
}